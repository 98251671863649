import React from "react"
import { Link } from "gatsby"
import Seo from "../components/Seo"
const OluPikselTamiri = () => {
  return (
    <>
      <Seo
        title="Ölü Piksel Tamiri"
        description="Tablet, telefon, televizyon, monitör ve bilgisayar ekranlarındaki ölü piksellerin tamirinin nasıl yapılacağına dair bilgilendirme..."
      />
      <h1 className="text-center md:text-left">
        Ölü Piksel Tamiri Nasıl Yapılır?
      </h1>

      <blockquote>
        Ölü piksel nedir, ölü piksel nasıl tamir edilir, ölü piksel sorunu nasıl
        giderilir?
      </blockquote>
      <h2>Ölü Piksel Nedir?</h2>
      <p>
        <code>Ölü piksel</code> ekranda genellikle enerji alamayan, enerji
        alamadığı için de siyah gözüken küçük noktalardır. Daha çok LCD
        ekranlarda görülür, transistörlerin işlevini kaybetmesi nedeniyle
        oluşur.
      </p>
      <h2>Ölü Pikselin Olduğunu Nasıl Anlarım?</h2>
      <p>
        Aşağıdaki <code>Ölü Piksel Testi</code> düğmesine tıklayarak açılan
        sayfada yönergeleri takip ederek <strong>ölü</strong> piksel testinin
        yanı sıra <strong>sıkışmış</strong> piksel testini de yapabilirsiniz.
      </p>
      <div className="my-3 text-center">
        <Link
          to="/olu-piksel-testi"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white">Ölü Piksel Testi</span>
        </Link>
      </div>
      <h2>Ölü Piksel Nasıl Tamir Edilir?</h2>
      <p>
        Ölü piksel daha çok donanım ile ilişkili fiziki bir sorun olduğundan
        tamiri zordur, genellikle yazılımla çözülebilecek bir durum değildir. Bu
        nedenle cihazınızı satın aldığınızda vakit kaybetmeden ölü piksel
        testini yapmanızı öneririz. İlk zamanlar değişim ve iade haklarınızı
        kullanmanız daha kolay olur.
      </p>
      <p>
        Eğer değişim ve iade hakkınız yoksa genelde ölü piksel panel ve ekran
        değişimi ile çözülür.
      </p>
      <p>
        Öte yandan düşük de olsa ölü piksel sorunu baskı yöntemiyle çözülebilir.
      </p>
      <blockquote>
        Bu yöntemi uygularken ekranı çizebilecek sert cisimler kullanmayın,
        cismi fazla bastırmayın. Dikkatli olmazsanız ekranınıza zarar
        verebilirsiniz.
      </blockquote>
      <p>
        Ölü pikseli baskı yöntemiyle giderirken genelde ucunda silgi olan
        kalemler kullanılır. Buna ek olarak kullanacağınız cismi yumuşak bir
        kumaşla sarmanız iyi olacaktır.
      </p>
      <h3>Adım Adım Ölü Piksel Tamiri</h3>
      <ol>
        <li>Ölü pikselin yerini ölü piksel testi yaparak tespit edin.</li>
        <li>Cihazınızı tamamen kapatın.</li>
        <li>
          Baskı için bir cisim kullanacaksanız mutlaka onu yumuşak bir bezle
          sarmalayın.
        </li>
        <li>
          Ölü pikselin olduğu yere yumuşak beze sarılmış bir cisim (kalem, silgi
          vb.) ile <strong>hafif</strong> baskı yapın.
        </li>
        <li>Baskı uygularken ekran ve bilgisayarınızı açın.</li>
      </ol>
      <div className="px-3 py-2 rounded-md shadow-sm bg-blue-50">
        <p>
          Yukarıdaki işlemi uygulamadan önce, özellikle eğer ekranda gördüğünüz
          nokta siyah harici bir renge sahipse{" "}
          <code>Sıkışmış Piksel Tamir Aracını</code> çalıştırmanızda fayda
          vardır. <strong>Sıkışmış</strong> pikseller ölü piksellere nazaran
          daha kolay tamir edilebilir. Daha detaylı bilgi almak ve{" "}
          <code>Sıkışmış Piksel Tamir Aracını</code> kullanmak için aşağıdaki{" "}
          <code>Sıkışmış Piksel Tamiri</code> düğmesine tıklayabilirsiniz.
        </p>
        <div className="pb-5 text-center">
          <Link
            to="/sikismis-piksel-tamiri"
            style={{ textDecoration: "none" }}
            className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
          >
            <span className="text-white">Sıkışmış Piksel Tamiri</span>
          </Link>
        </div>
      </div>
      <p>
        <code>Ölü piksel garantiye girer mi?</code> sorusunun cevabı ise firma
        ve süreye bağlı olarak değişir. Türkiye'de genelde firmalar 14 gün
        içerisinde kolay iade imkanı sunmaktadır. 14 günü geçirdiyseniz satın
        aldığınız firmaya üzerinden müşteri hizmetleri ya da teknik servise
        ulaşıp bilgi almanız daha doğru olacaktır. Zira ekran kenarında olan ölü
        pikseller garanti bazı firmalar tarafından normal görülmekte, değişim ve
        iade yapılmamaktadır.
      </p>
    </>
  )
}
export default OluPikselTamiri
